import moment from "moment";
import { env, RegionUrl } from "../../config/config.js";
import { reactLocalStorage } from "reactjs-localstorage";
import { REGION_OBJ, LSNAME, ROLE, PRODUCT_CATEGORY, LSFILTER, REGLSFILTER } from "../constants/constants.js";
import { RegionOption } from "../constants/options.js";
import _ from 'lodash';

const Joi = require("joi-browser");
const joiToForms = require("joi-errors-for-forms").form;
const convertToForms = joiToForms();

export const truncateText = (s, maxLength) => {
  if (!s) {
    return "";
  }

  if (s.length < maxLength) {
    return s;
  }

  return s.substring(0, maxLength) + "...";
};

export const validateEditedData = ({ editedData, joiSchema, schema }) => {
  // example code

  // joiSchema obj is deprecated

  // get joiFormErrors() {
  //   return validateEditedData({
  //     editedData: this.state.data,
  //     schema: (Joi) => ({
  //       parentId: Joi.object().required().error(() => {
  //         return {
  //           message: "Required"
  //         }
  //       }),
  //       name: Joi.string().required().error(() => {
  //         return {
  //           message: "Required"
  //         }
  //       }),
  //       orderNumber: Joi.alternatives(Joi.string(), Joi.number()).required().error(() => {
  //         return {
  //           message: "Required"
  //         }
  //       }),
  //     })
  //   })
  // }

  // in hooks
  //   const joiFormErrors = {
  //     get error() {
  //         return validateEditedData({
  //             editedData: form,
  //             schema: (Joi) => ({
  //                 name: Joi.string().required().error(() => {
  //                     return {
  //                         message: "Required"
  //                     }
  //                 }),
  //                 category: Joi.array().min(1).error(() => {
  //                     return {
  //                         message: 'Required'
  //                     }
  //                 }),
  //             })
  //         })
  //     }
  // }
  const joiResult = Joi.validate(editedData, schema ? schema(Joi) : joiSchema, {
    convert: true,
    abortEarly: false,
    stripUnknown: true
  });
  if (!joiResult.error) {
    return null;
  }
  return convertToForms(joiResult.error);
};

export const numberFormatter = (n, options = {}) => {
  if (typeof n !== 'number') {
    if (typeof Number(n) !== 'number') {
      return '-';
    }
    n = Number(n);
  }
  return new Intl.NumberFormat("en-US", options).format(n);
};

export const convertEndDate = date => {
  return moment(date)
    .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
    .toISOString();
};

// set and get region
export const getRegion = () => {
  try {
    let region = REGION_OBJ.hk.code;
    let ls = reactLocalStorage.get(LSNAME.REGION)
    if (ls && ls !== 'undefined') {
      region = reactLocalStorage.get(LSNAME.REGION);
    } else {
      clearLS();
      setRegion(region);
    }
    let pathRegion = region
    if ( window && window.location && window.location.pathname) {
      let pathname = window.location.pathname;
      if (pathname.includes('/hk/')) {
        pathRegion = REGION_OBJ.hk.code;
      } else if (pathname.includes('/tw/')) {
        pathRegion = REGION_OBJ.tw.code;
      }
    }
    if (pathRegion !== region) {
      let ls = reactLocalStorage.getObject(LSNAME.LIST_REGION);
      if (!_.isEmpty(ls) && ls.indexOf(pathRegion) >= 0 ){ region = pathRegion }
    }
    return region;
  } catch (error) {
    console.error(error);
  }
}
export const getTemplate = (value) => {
  try {
    let template = reactLocalStorage.getObject(LSNAME.CHAT_TEMPLATE);
    if (value) { template = _.filter(template, 
        item => {
          return item.template.toLowerCase() === value.toLowerCase();
      }
      // {template: value.toString()}
      ); }
    if (!value || value === '') { template = [] }
    return template
  } catch (error) {
    console.log(error)
  }
}
export const findRegionOption = (list, regionOption = RegionOption) => {
  try {
    let data = [];
    if (list && list.length > 0) {
      data = list.map(v => regionOption.find(ro => ro.value === v));
    }
    return data;
  } catch (error) {
    console.error(error);
  }
}
export const setRegion = (region) => {
  try {
    reactLocalStorage.set(LSNAME.REGION, region);
  } catch (error) {
    console.error(error)
  }
}
export const setTemplate = (template) => {
  try {
    reactLocalStorage.setObject(LSNAME.CHAT_TEMPLATE, template);
  } catch (error) {
    console.error(error)
  }
}
export const clearLS = () => {
  try {
    reactLocalStorage.remove(LSNAME.DASHBOARD);
    reactLocalStorage.remove(LSNAME.TOKEN);
    reactLocalStorage.remove(LSNAME.PERMISSION);
    reactLocalStorage.remove(LSNAME.ADMIN);
    reactLocalStorage.remove(LSNAME.CHAT_TEMPLATE);

    // clear filter
    // every log out clear all filter 
    for (let filter in LSFILTER) {
      reactLocalStorage.remove(LSFILTER[filter]);
    }
    for (let filter in REGLSFILTER) {
      reactLocalStorage.remove(REGLSFILTER[filter]["hk"]);
      reactLocalStorage.remove(REGLSFILTER[filter]["tw"]);
    }
  } catch (error) {
    console.error(error);
  }
}
// currency
export const setCurrency = (value, region = getRegion()) => {
  try {
    const a = region ? region : getRegion();
    const regionCurrency = REGION_OBJ[a].currency;
    if ((value && Number(value)) || value === 0 || value === '0') {
      return `${regionCurrency} ${numberFormatter(value)}`;
    }
    return '-';
  } catch (error) {
    console.error(error);
  }
}

// date
export const setDateFormat = (date, format = 'YYYY/MM/DD H:mm:ss') => {
  try {
    if (date) {
      return moment(date).format(format);
    }
    return "-";
  } catch (error) {
    console.error(error);
    return '-';
  }
}

export const setURL = (url, region = getRegion()) => {
  try {
    const r = region ? region : getRegion();
    return `/${r}/${url}`;
  } catch (error) {
    console.error(error);
    return '-';
  }
}

// role
export const checkRole = (role = ROLE.SUPERADMIN) => {
  // can array
  try {
    let isRole = false;
    const admin = reactLocalStorage.getObject(LSNAME.ADMIN);
    if (admin && admin !== {}) {
      if (Array.isArray(role)) {
        isRole = role.includes(admin.role);
      } else {
        if (admin.role === role || admin.role === ROLE.DEVELOPER) {
          isRole = true;
        }
        // isRole = admin.role === role ? true : false;
      }
    }
    return isRole;
  } catch (error) {
    console.log('checkRole', error);
  }
}
export const getRole = () => {
  try {
    const admin = reactLocalStorage.getObject(LSNAME.ADMIN);
    if (admin && admin !== {}) {
      return admin.role ? admin.role : '';
    }
    return '';
  } catch (error) {
    console.log('getRole', error);
  }
}
export const getAdminMulti = () => {
  try {
    const admin = reactLocalStorage.getObject(LSNAME.ADMIN);
    if (admin && admin !== {}) {
      return admin.region ? admin.region : [];
    }
    return '';
  } catch (error) {
    console.log('getRole', error);
  }
}

export const getURLAPI = (region = getRegion(), environment = env, path = 'adminUrl') => {
  try {
    let url = '';
    if (region && env && RegionUrl[region] && RegionUrl[region][environment]) {
      url = RegionUrl[region][environment][path];
    }
    return url;
  } catch (error) {
    console.log('geturlapi', error);
  }
}

// setimage url
export const setURLImage = (productImages = '', filename = '', isOWNPOP = '') => {
  let isFullURL = isOWNPOP === PRODUCT_CATEGORY.MERCHANT;
  let imageUrl = '';
  if (isFullURL) {
    // imageUrl = productImages;
    imageUrl = `//images.weserv.nl/?url=${productImages}&filename=${filename}`;
  } else {
    // imageUrl = `//images.weserv.nl/?url=https://id.360buyimg.com/Indonesia/${productImages}`;
    imageUrl = `https://id.360buyimg.com/Indonesia/${productImages}`;
  }
  return imageUrl;
}

// rows
export const getAndroidOrIOS = (data) => {
  try {
    let icon = 'fa fa-android';
    let color = '#3DDC84'
    if (data === 'ios') {
      icon = 'fa fa-apple';
      color = 'silver'
    }
    return {
      icon,
      color
    }
  } catch (error) {

  }
}
