import { REGION_OBJ, LSNAME } from '../helper/constants/constants';
import { reactLocalStorage } from "reactjs-localstorage";
import _ from 'lodash';

// module.exports = { apiUrl, adminUrl, awsConfig };
// import { getRegion } from '../helper/functions/functions';
const RegionUrl = {
  hk: {
    development: {
      webUrl: 'https://i-dealku.com',
      // adminUrl: 'http://192.168.1.251:5000/admin',
      // chatUrl:  'http://192.168.1.251:5000/chat',
      // adminUrl: 'http://localhost:5000/admin',
      // chatUrl:  'http://localhost:5000/chat',
      // employeeUrl:  'http://localhost:5000/employee',
      // monitoringUrl: 'http://localhost:5000/monitoring',
      adminUrl: 'https://apidev.i-dealhomeonline.com/admin',
      apiUrl: "https://apidev.i-dealhomeonline.com/api",
      chatUrl:  'https://apidev.i-dealhomeonline.com/chat',
      employeeUrl:  'https://apidev.i-dealhomeonline.com/employee',
      monitoringUrl: 'https://apidev.i-dealhomeonline.com/monitoring',
      // adminUrl: 'http://192.168.1.11:5000/admin',
      // adminUrl: 'https://api.i-dealhomeonline.com/admin',
      // adminUrl: 'https://apiprod-admin.i-dealhomeonline.com/admin',
      // chatUrl:  'https://apiprod-admin.i-dealhomeonline.com/chat',
      // employeeUrl:  'https://apiprod-admin.i-dealhomeonline.com/chat',
      // employeeUrl: 'https://api-staging.i-dealhomeonline.com/employee',
      // apiUrl: "https://apiprod.i-dealhomeonline.com/api",
      absentUrl: "https://attendance.i-dealhomeonline.com/",
    },
    production: {
      webUrl: 'https://i-dealku.com',
      adminUrl: 'https://apiprod-admin.i-dealhomeonline.com/admin',
      apiUrl: "https://apiprod.i-dealhomeonline.com/api",
      absentUrl: "https://attendance.i-dealhomeonline.com/",
      employeeUrl: 'https://apiprod-admin.i-dealhomeonline.com/employee',
      chatUrl:  'https://apiprod-admin.i-dealhomeonline.com/chat',
      monitoringUrl: 'https://apiprod-admin.i-dealhomeonline.com/monitoring',
    }
  },
  tw: {
    development: {
      webUrl: 'https://i-dealku.com',
      // adminUrl: 'http://localhost:5000/admin',
      adminUrl: 'https://apidev-tw.i-dealhomeonline.com/admin',
      chatUrl: 'https://apidev-tw.i-dealhomeonline.com/chat',
      apiUrl: 'https://apidev-tw.i-dealhomeonline.com/api',
      monitoringUrl: 'https://apidev-tw.i-dealhomeonline.com/monitoring',
      employeeUrl: 'https://apidev-tw.i-dealhomeonline.com/employee',
    },
    production: {
      webUrl: 'https://i-dealku.com',
      adminUrl: 'https://apiprod-tw.i-dealhomeonline.com/admin',
      employeeUrl: 'https://apiprod-tw.i-dealhomeonline.com/employee',
      apiUrl: "https://apiprod-tw.i-dealhomeonline.com/api",
      chatUrl:  'https://apiprod-tw.i-dealhomeonline.com/chat',
      monitoringUrl: 'https://apiprod-tw.i-dealhomeonline.com/monitoring',
    }
  }
}
let regionValue = REGION_OBJ.hk.code;
// if (reactLocalStorage.get(LSNAME.REGION)) {
//   regionValue = reactLocalStorage.get(LSNAME.REGION);
// }
let lsRegion = reactLocalStorage.get(LSNAME.REGION);
if (lsRegion && lsRegion !== 'undefined') {
  regionValue = lsRegion;
} else {
  reactLocalStorage.clear();
}

// console.log(regionValue)

// let query = qs.parse(window.location.search);
// console.log(query)
// if (query && query.extregion) {
//   if (query.extregion !== lsRegion) {
//     regionValue = query.extregion
//   }
// }

let pathRegion = regionValue
if (window && window.location && window.location.pathname) {
  let pathname = window.location.pathname;
  if (pathname.includes('/hk/')) {
    pathRegion = REGION_OBJ.hk.code;
  } else if (pathname.includes('/tw/')) {
    pathRegion = REGION_OBJ.tw.code;
  }
}

if (pathRegion !== regionValue) {
  let ls = reactLocalStorage.getObject(LSNAME.LIST_REGION);
  if (!_.isEmpty(ls) && ls.indexOf(pathRegion) >= 0 ){ regionValue = pathRegion }
}

// console.log(regionValue)

let environments = {
  development: {
    region: regionValue,
    absentUrl: RegionUrl[regionValue].development.absentUrl,
    adminUrl: RegionUrl[regionValue].development.adminUrl,
    chatUrl: RegionUrl[regionValue].development.chatUrl,
    employeeUrl: RegionUrl[regionValue].development.employeeUrl,
    apiUrl: RegionUrl[regionValue].development.apiUrl,
    webUrl: RegionUrl[regionValue].development.webUrl,
    monitoringUrl: RegionUrl[regionValue].development.monitoringUrl,
    brochureURL: "https://staging-brochure.netlify.com",
    mainURL: 'https://apidev.i-dealhomeonline.com/admin',
    smsUrl: "https://sms.i-dealhomeonline.com/",
    awsConfig: {
      bucketName: 'ideal-homeonline-staging',
      // dirName: 'brands', /* optional */
      region: 'ap-southeast-1',
      accessKeyId: 'AKIATOQMWBSZGJGSHLE5',
      secretAccessKey: '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',
      secretKeyId: 'lI/AP3QJ4970b6bDMTmX/dtABJRBHBnTdP3ZmZsZ' // same as secretAccessKey
    },
    pusher: {
      app_id: "1085017",
      key: "b9eac7a97675bed52446",
      secret: "97c92a3d5b80d62e6449",
      cluster: "ap1",
      channel: "idealcms-staging"
    },
    onesignal: {
      appId: '5896a897-d322-4d5a-a88e-eb69b69aca4a'
    }
  },
  production: {
    adminUrl: RegionUrl[regionValue].production.adminUrl,
    chatUrl: RegionUrl[regionValue].production.chatUrl,
    employeeUrl: RegionUrl[regionValue].production.employeeUrl,
    apiUrl: RegionUrl[regionValue].production.apiUrl,
    webUrl: RegionUrl[regionValue].production.webUrl,
    monitoringUrl: RegionUrl[regionValue].production.monitoringUrl,
    absentUrl: RegionUrl[regionValue].production.absentUrl,
    brochureURL: "https://brochure.i-dealhomeonline.com",
    mainURL: 'https://apiprod-admin.i-dealhomeonline.com/admin',
    smsUrl: "https://sms.i-dealhomeonline.com/",
    awsConfig: {
      bucketName: 'ideal-homeonline-production',
      // dirName: 'brands', /* optional */
      region: 'ap-southeast-1',
      accessKeyId: 'AKIATOQMWBSZGJGSHLE5',
      secretAccessKey: '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',
      secretKeyId: 'lI/AP3QJ4970b6bDMTmX/dtABJRBHBnTdP3ZmZsZ' // same as secretAccessKey
    },
    onesignal: {
      appId: '1b0e1725-d99a-4e39-9613-81966c894009'
    },
    pusher: {
      app_id: "1085018",
      key: "940fda9c34120a2346bd",
      secret: "71ffbbe3776500654d3d",
      cluster: "ap1",
      channel: "idealcms-production"
    },
  }
}

const hostname = window && window.location && window.location.hostname
let env = 'development'
if (
  hostname &&
  (hostname.includes('staging.i-dealhomeonline.com') ||
    hostname.includes('ideal-dev.netlify.com')) || hostname.includes('idealadminstaging.netlify.app')
) {
  env = 'development'
} else if (
  hostname &&
  (hostname.includes('admin.i-dealhomeonline.com') ||
    hostname.includes('ideal.netlify.com'))
) {
  env = 'production'
} else if (hostname && hostname.includes('localhost')) {
  env = 'development'
} else if (hostname && hostname.includes('192.168.43.242')) {
  env = 'development'
} else if (hostname && hostname.includes('192.168.100.154')) {
  env = 'development'
} else if (hostname && hostname.includes('192.168.1.7')) {
  env = 'development'
}

let dataEnv = environments[env.toLowerCase()];

// const updateConfig = (region) => {
//   dataEnv = environments
//   adminUrl = dataEnv.adminUrl
//   // alert(JSON.stringify(dataEnv))
// }

let { region, adminUrl, apiUrl, chatUrl, employeeUrl, awsConfig, pusher, absentUrl, webUrl, smsUrl, monitoringUrl, mainURL, brochureURL } = dataEnv;

export {
  region,
  adminUrl,
  chatUrl,
  employeeUrl,
  apiUrl,
  awsConfig,
  pusher,
  absentUrl,
  env,
  RegionUrl,
  webUrl,
  smsUrl,
  monitoringUrl,
  mainURL,
  brochureURL
  // updateConfig,
}
