// style
// ##### option
// rule: [name]Option

import { MERCHANT_ORDER_TYPE, ORDER_TYPE, REGION_OBJ, ROLE } from "./constants";

export const AllOption = { value: '', label: 'All' };

export const RangeSelectOption = [
    { value: 'ib', label: '<>' },
    { value: 'eq', label: '=' },
    { value: 'lt', label: '<' },
    { value: 'gt', label: '>' },
]
export const UserMemberOption = [
    { value: 'regular', label: 'Regular' },
    { value: 'gold', label: 'Gold' },
    { value: 'diamond', label: 'Diamond' }
];

export const SaldoStatusOption = [
    AllOption,
    { value: 'DB', label: 'DB' },
    { value: 'CR', label: 'CR' },
]
export const RegionOption = [
    { value: REGION_OBJ.hk.code, label: REGION_OBJ.hk.name },
    { value: REGION_OBJ.tw.code, label: REGION_OBJ.tw.name },
];

export const CurrRegionList = ({region, listRegion}) => {
    let newListRegion = [{ value: REGION_OBJ[region].code, label: REGION_OBJ[region].name }]
    listRegion.map( lR => {
        if (lR.value !== region) { newListRegion.push(lR) }
    })
    return newListRegion;
}

// - product
export const AvailableOption = [
    { value: '', label: 'All' },
    { value: 'true', label: 'Tersedia' },
    { value: 'false', label: 'Tidak Tersedia' }
];
export const TodayChangesOption = [
    { value: 'new', label: 'New' },
    { value: 'up', label: 'Up' },
    { value: 'down', label: 'Down' }
];
export const MerchantOption = [
    { value: '', label: 'Semua' },
    { value: '1', label: 'OWN' },
    { value: '0', label: 'POP' },
    { value: '88', label: 'MERCHANT' },
];
export const DiscountStatusOption = [
    { value: 'true', label: 'YES' },
    { value: 'false', label: 'NO' }
];
export const DeleteStatusOption = [
    { value: '', label: 'Semua' },
    { value: 'true', label: 'Deleted' },
    { value: 'false', label: 'Tersedia' }
];

// - digital product 
export const LockOption = [
    { value: true, label: 'Locked' },
    { value: false, label: 'Unlocked' }
];

// collection
export const ActiveOption = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' }
];

export const YesNoOption = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
];

// banner
export const LinkToBannerOption = [
    { value: 0, label: 'None' },
    { value: 1, label: 'Category' },
    { value: 2, label: 'Collection' },
    { value: 3, label: 'Digital Product' },
    { value: 4, label: 'External Link' },
    // { value: 5, label: 'Brochure' },
];
export const DigitalProductBannerOption = [
    { value: 1, label: 'Pulsa' },
    { value: 2, label: 'Paket data' },
    { value: 3, label: 'PLN' },
];

export const OrderTypeOption = [
    { value: ORDER_TYPE.DIGITAL, label: 'Digital', },
    { value: ORDER_TYPE.ID, label: 'ID' },
];

// brochure
export const BrochureLinkToOptions = [
    {value: 'collection', label: 'Collection'},
    {value: 'category', label: 'Categories'},
    {value: 'idproduct', label: 'ID Product'},
    {value: 'digital', label: 'Digital'},
    // {value: 'content', label: 'Brochure Content'}
];

// order
export const OrderStatusOption = [
    // { value: 'pending', label: 'Pending' },
    // { value: 'submit', label: 'Submit' },
    { value: 'awaiting_delivery', label: 'Menunggu Pengiriman' },
    { value: 'awaiting_payment', label: 'Menunggu Pembayaran' },
    { value: 'completed', label: 'Selesai' },
    { value: 'late', label: 'Telat Bayar' },
    { value: 'cancelled', label: 'Batal' },
    { value: 'failed', label: 'Gagal' },
    // { value: 'refund', label: 'Refund' },
];

export const OrderStatusIndoOption = [
    // { value: "", label: "All" },
    { value: "menunggu_pengiriman", label: "Menunggu Pengiriman" },
    { value: "menunggu_pembayaran", label: "Menunggu Pembayaran" },
    { value: "selesai", label: "Selesai" },
    { value: "telat_bayar", label: "Telat Bayar" },
    { value: "batal", label: "Batal" },
    { value: "gagal", label: "Gagal" },
]

// coupon
export const DiscountOption = [
    { value: 'percentage', label: 'Percentage' },
    { value: 'nominal', label: 'Nominal' }
];

export const VendorOption = [
    { value: '', label: 'All' },
    { value: 'mobilepulsa', label: 'Mobile Pulsa' },
    { value: 'smtel', label: 'SMTEL' },
];

// merchant
export const MerchantStatusShippingOption = [
    { value: '', label: 'All' },
    { value: MERCHANT_ORDER_TYPE.NEW_ORDER, label: 'New' },
    { value: MERCHANT_ORDER_TYPE.ACCEPTED_ORDER, label: 'Menunggu Dikirim' },
    { value: MERCHANT_ORDER_TYPE.ORDER_SENT, label: 'Pesanan Sedang Dikirim' },
    { value: MERCHANT_ORDER_TYPE.BAST_APPROVAL, label: 'BAST Approval' },
    { value: MERCHANT_ORDER_TYPE.ORDER_RECEIVED, label: 'Telah Diterima' },
    { value: MERCHANT_ORDER_TYPE.SETTLEMENT_ACCEPTED, label: 'Settled' },
    { value: MERCHANT_ORDER_TYPE.REJECTED_ORDER, label: 'Rejected' }
];

// report
export const ReportDateSettlementOption = [
    AllOption,
    { value: "10", label: "10" },
    { value: "25", label: "25" }
];

export const ReportPaymentStatusOption = [
    AllOption,
    { value: "unpaid", label: "Belum Bayar" },
    { value: "paid", label: "Selesai" },
    { value: "cancelled", label: "Dibatalkan" },
    { value: "late", label: "Telat" }
];

export const ReportLatePaymentStatusOption = [
    { value: "", label: "None" },
    { value: "unpaid", label: "Unpaid" },
    { value: "paid", label: "Paid" },
    { value: "cancelled", label: "Cancelled" },
    { value: "abnormal", label: "Abnormal" },
    { value: "baddebt1", label: "Bad Debt 1" },
    { value: "baddebt2", label: "Bad Debt 2" },
    { value: "baddebt3", label: "Bad Debt 3" },
];

export const PaymentStatusOption = [
    { value: "unpaid", label: "Unpaid" },
    { value: "paid", label: "Paid" },
    { value: "cancelled", label: "Cancelled" },
    { value: "abnormal", label: "Abnormal" },
    { value: "baddebt1", label: "Bad Debt 1" },
    { value: "baddebt2", label: "Bad Debt 2" },
    { value: "baddebt3", label: "Bad Debt 3" },
];

export const RoleOption = [
    { value: ROLE.SUPERADMIN, label: "Super Admin" },
    { value: ROLE.MARKETING, label: "Marketing" },
    { value: ROLE.MANAGEMENT, label: "Management" },
    { value: ROLE.BOD, label: "Board of Directors" }
];

export const CallStatusOption = [
    { value: "followup", label: "Follow Up" },
    { value: "closed", label: "Closed" },
];

export const DirectionOption = [
    { value: "inbound", label: "Inbound" },
    { value: "outbound", label: "Outbound" },
    { value: "inout", label: "In-Out" }
];

export const TranslateTypeOption = [
    AllOption,
    { value: "textTranslation", label: "Text" },
    { value: "speechTranslation", label: "Speech" }
];
