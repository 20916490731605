// style
export const StylesReactSelect = {
    control: base => ({
        ...base,
        borderColor: '#e4e7ea',
        color: '#5c6873',
        maxHeight: '35px',
        minHeight: '35px',
        '&:hover': {
            borderColor: '#e4e7ea'
        },
        //   textOverflow: 'ellipsis',
        //   overflow: 'hidden',
        //   whiteSpace: 'nowrap',
    })
}
export const ChartColor = {
    backgroundColor: '#FE5522',
    borderColor: '#FE5522',
    borderWidth: 1
}
// data 
export const Template = {
    Type_SMSServer: 'smsServer',
    Type_Notification: 'notification',
}
export const REGION_OBJ = {
    hk: {
        code: 'hk',
        codeCaps: 'HK',
        operator: '852',
        name: 'Hong Kong',
        currency: 'HK$',
        idCard: 'HKID',
        idCardName: 'HK ID'
    },
    tw: {
        code: 'tw',
        codeCaps: 'TW',
        operator: '886',
        name: 'Taiwan',
        currency: 'NT$',
        idCard: 'ARCID',
        idCardName: 'ARC'
    },
}
export const DIRNAMES3 = {
    BANNER: 'banner_images',
    CATEGORY: 'JDCategory_images',
    MERCHANT: 'merchantproduct',
    COLLECTION: 'Collection_images',
    DOCUMENT: 'userdocuments',
    BRAND: 'brands',
    HOMEPAGE: 'homepage_images',
    APPCHANGELOG: 'app_changelog',
    CHATMESSAGE: 'chat_message',
}
export const ROLE = {
    MARKETING: 'marketing',
    MANAGEMENT: 'management',
    SUPERADMIN: 'superadmin',
    BOD: 'bod',
    DEVELOPER: 'developer',
}

export const ORDER_TYPE = {
    DIGITAL: 'digital',
    ID: 'jd',
}
export const PRODUCT_CATEGORY = {
    OWN: '1',
    POP: '0',
    MERCHANT: '88',
};

export const MERCHANT_ORDER_TYPE = {
    NEW_ORDER: 'NEW ORDER',
    ACCEPTED_ORDER: 'ACCEPTED ORDER',
    ORDER_SENT: 'ORDER SENT',
    BAST_APPROVAL: 'BAST APPROVAL',
    ORDER_RECEIVED: 'ORDER RECEIVED',
    SETTLEMENT_ACCEPTED: 'SETTLEMENT ACCEPTED',
    REJECTED_ORDER: 'REJECTED ORDER',
}

export const LIST_MONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];

// object for filter result
export const FilterResult = {
    rows: [],
    loading: false,
    page: 0,
    totalData: 0,
    limit: 20,
}

// local storage
export const LSNAME = {
    TOKEN: 'user_login',
    PERMISSION: 'adminPermission',
    ADMIN: 'idealCurrentUser',
    DASHBOARD: 'newDashboard',
    REGION: 'region',
    LIST_REGION: 'list_region',
    KPI: 'kpi',
    TIMETABLE: 'time_table',
    KPI_SOCKET: 'kpi_socket',
    CHAT_TEMPLATE: 'chat_template'
}
// #object filter follow order
// example object
// {
//     filter: .....,
//     page: ..
// }
export const LSFILTER = {
    ORDER: 'filter_order',
    LOGCALL: 'filter_logcall',
    USER: 'filter_user',
    CATEGORY_ID: 'filter_category_id',
    PRODUCT_ID: 'filter_product_id',
    PRODUCT_DIGITAL: 'filter_product_digital',
    REPORT_SETTLEMENT: 'filter_report_settlement',
}

export const REGLSFILTER = {
    ORDER: {
        'hk' : 'filter_order_hk',
        'tw' : 'filter_order_tw'
    },
    LOGCALL: {
        'hk' : 'filter_logcall_hk',
        'tw' : 'filter_logcall_tw'
    },
    USER: {
        'hk' : 'filter_user_hk',
        'tw' : 'filter_user_tw'
    },
    BANNER: {
        'hk' : 'filter_banner_hk',
        'tw' : 'filter_banner_tw'
    },
    POPUP: {
        'hk' : 'filter_popup_hk',
        'tw' : 'filter_popup_tw'
    },
    COLLECTION: {
        'hk' : 'filter_collection_hk',
        'tw' : 'filter_collection_tw'
    },
    CATEGORY_ID: {
        'hk' : 'filter_category_id_hk',
        'tw' : 'filter_category_id_tw'
    },
    PRODUCT_ID: {
        'hk' : 'filter_product_id_hk',
        'tw' : 'filter_product_id_tw'
    },
    PRODUCT_DIGITAL: {
        'hk' : 'filter_product_digital_hk',
        'tw' : 'filter_product_digital_tw'
    },
    REPORT_SETTLEMENT: {
        'hk' : 'filter_report_settlement_hk',
        'tw' : 'filter_report_settlement_tw'
    },
}